<template>
    <div class="index">

        <div v-if="!!userEntity">

            <div class="el-card-container">
                    <div class="basicInfo-container">
                        <div class="basicInfo-container-left">
                            <div style="text-align: center;padding-right: 20px">
                                <template v-if="userEntity">
                                    <el-avatar :src="this.$oucy.ossUrl+userEntity.detailAvatar"></el-avatar>
                                </template>
                                <template v-else>
                                    <el-avatar icon="el-icon-user-solid"></el-avatar>
                                </template>
                                <div>
                                    {{userEntity?userEntity.detailNickname:'未设置昵称'}}
                                </div>
                            </div>
                            <div class="id-container">
                                <div>
                                    <el-input id="copydom" v-model.number="name" placeholder="请输入密码" style="position: absolute;top: -1000px;left: -10000px;opacity: 0;"></el-input>
                   
                                    用户名： <span v-html="getName()"></span>
                                    <el-button size="mini" icon="el-icon-copy-document" type="text" style="color: #20A0FF;" @click="copyName">复制</el-button>
                                    <!-- 没有设置登录用户名和密码的提示 -->
                                    <el-button type="text" style="color: #f00;" v-if="!userEntity.userDcodeUsername" @click="$oucy.go('/fullaccount')">您还未设置登录用户名和密码，快去设置吧！</el-button>
                                </div>
                                <div>
                                    实名认证：
                                    <template v-if="userEntity.userPersonalAuthenticationStatu">
                                        <i class="el-icon-success" style="color: #1aad19;">已认证</i>
                                    </template>
                                    <template v-else>
                                        <el-tag type="info">未认证</el-tag>
                                        <el-button @click="$oucy.replace('/acc/realname', null)" size="mini" type="text" style="margin-left: 20px;color: #20A0FF;">立即认证 >></el-button>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div>
                            <el-button @click="go('/acc/setup')" icon="el-icon-setting" type="primary" size="mini">账号设置</el-button>
                            <div class="m-t-10">
                                <el-button @click="go('/acc/EditUser')" icon="el-icon-edit" type="primary" size="mini">资料修改</el-button>
                            </div>
                        </div>
                    </div>
            </div>
            <div>
                <!-- 没有进行买家认证 并没有进行企业入驻的显示这个图 -->
                <template v-if="!isEnterpriseOrBuyAuth">
                    <img src="../../../assets/personal/buyer_iden.png" @click="$oucy.go('/acc/identity')" style="cursor: pointer;margin-bottom: 20px;width:100%">
                </template>
                <template v-if="this.$oucy.buyerAuthenticationStatus.APPLY_SUCCESS == userEntity.userBuyerAuthenticationStatu">
                </template>
            </div>
            <div class="el-card-container">
                    <div slot="header" class="my-card-header">
                        <div class="my-card-header-money">
                            <span style="font-size: 16px;color: #555C68;padding:0 5px 0 0">当前余额：</span>
                                <span style="font-size: 16px;color: #F09953;">¥{{userEntity.userBalance}}</span><span style="font-size: 16px;color: #555C68;">元</span>
                                <el-button style="float: right; padding: 3px 0;margin-left:30px" icon="el-icon-s-finance" type="text" @click="$oucy.go('/acc/wallet')">进入钱包
                                </el-button>
                        </div>
                        <!-- 入驻了的企业就不能卖家身份认证了 -->
                        <div v-if="!$store.state.statu">
                            <template v-if="this.$oucy.buyerAuthenticationStatus.WAITING_FOR_APPLY == userEntity.userBuyerAuthenticationStatu">
                                <!-- 未有进行所有认证的 显示这个 -->
                                <el-button @click="$oucy.go('/acc/identity')" style="float: right; padding: 3px 0" type="text">我要进行身份认证</el-button>
                            </template>
                            <template v-else-if="this.$oucy.buyerAuthenticationStatus.APPLY_FAIL == userEntity.userBuyerAuthenticationStatu">
                                <!-- 买家身份认证未通过的 显示这个 -->
                                <el-button @click="$oucy.go('/acc/identity')" class="warning_auth" type="text"><img src="../../../assets/icon/iden_warning.png"> 您的买家身份认证未通过，请重新认证！</el-button>
                            </template>
                            <template v-else-if="this.$oucy.buyerAuthenticationStatus.APPLYING == userEntity.userBuyerAuthenticationStatu">
                                <el-button @click="$oucy.go('/acc/identity')" style="float: right; padding: 3px 0" type="text">身份认证审核中</el-button>
                            </template>
                             <!-- @click="$oucy.go('/acc/identity')" -->
                            <template v-else-if="this.$oucy.buyerAuthenticationStatus.APPLY_SUCCESS == userEntity.userBuyerAuthenticationStatu">
                                <el-button style="float: right; padding: 3px 0" type="text">已完成买家身份认证</el-button>
                            </template>
                            <template v-else-if="this.$oucy.buyerAuthenticationStatus.WAITING_FOR_APPLY !== userEntity.userEnterpriseEnterStatu">
                                <!-- 如果入驻了就 -->
                                <el-button @click="$oucy.go('/acc/identity')" style="float: right; padding: 3px 0" type="text">已是卖家身份</el-button>
                            </template>
                        </div>
                       <div v-if="$store.state.statu==4">
                                  <template v-if="this.$oucy.buyerAuthenticationStatus.WAITING_FOR_APPLY !== userEntity.userEnterpriseEnterStatu">
                                <!-- 如果入驻了就 -->
                                <el-button style="float: right; padding: 3px 0;color:#FF0000" type="text">已入驻企业无法认证买家</el-button>
                            </template>
                        </div>
                    </div>
                    <div class="info-item-container">
                        <div style="width: 100px;">
                            <el-card :body-style="{ padding: '15px 0 10px 0', textAlign: 'center' }" shadow="hover">
                                <img src="../../../assets/icon/bdphong_icon.png" class="image"  @click="$oucy.go('/acc/setup')">
                                <div class="info-item-desc"  @click="$oucy.go('/acc/setup')">
                                    <template v-if="userEntity.userPhoneNumber">
                                        {{userEntity.userPhoneNumber}}
                                    </template>
                                    <template v-else>
                                        未绑定
                                    </template>
                                </div>
                            </el-card>
                        </div>
                        <div style="width: 100px;">
                            <el-card :body-style="{ padding: '15px 0 10px 0', textAlign: 'center' }" shadow="hover">
                                <img src="../../../assets/icon/wechat_icon.png" class="image">
                                <div class="info-item-desc">
                                     {{userEntity&&userEntity.detailWechatNickname?userEntity.detailWechatNickname:'未绑定'}}
           <!--                          <template v-if="userEntity.userWechatUnicode">
                                        {{userEntity.userWechatUnicode.slice()}}
                                    </template>
                                    <template v-else>
                                        未绑定
                                    </template>
                                     -->
                                </div>
                            </el-card>
                        </div>
                        <div style="width: 100px;">
                            <el-card :body-style="{ padding: '15px 0 10px 0', textAlign: 'center' }" shadow="hover" >
                                <img src="../../../assets/icon/mycard_icon.png" class="image" @click="$oucy.go('/acc/cardDetail')">
                                <div class="info-item-desc" @click="$oucy.go('/acc/cardDetail')">
                                    名片
                                </div>
                            </el-card>
                        </div>
                        <div style="width: 100px;">
                            <el-card :body-style="{ padding: '15px 0 10px 0', textAlign: 'center' }" shadow="hover">
                                <img src="../../../assets/icon/scgl_icon.png" class="image">
                                <div class="info-item-desc">
                                    收藏
                                </div>
                            </el-card>
                        </div>                        
                    <div style="width: 100px;">
                        <el-card :body-style="{ padding: '15px 0 10px 0', textAlign: 'center' }" shadow="hover">
                            <img src="../../../assets/icon/coupon_icon.png" class="image" @click="$oucy.go('/acc/MyCoupon')">
                            <div class="info-item-desc">
                                我的优惠券
                            </div>
                        </el-card>
                    </div>
                        <div style="width: 100px;">
                          <el-card :body-style="{ padding: '15px 0 10px 0', textAlign: 'center' }" shadow="hover">
                                <img src="../../../assets/icon/ddgg_icon.png" class="image" @click="$oucy.go('/acc/OMS')">
                                <span class="info-item-desc" @click="$oucy.go('/acc/OMS')">
                                    订单管理
                                </span>
                          </el-card>
                        </div>
                        <div style="width: 100px;">
                            <el-card :body-style="{ padding: '15px 0 10px 0', textAlign: 'center' }" shadow="hover" >
                                <img src="../../../assets/icon/jhc_icon.jpg" class="image" @click="$oucy.go('/Address')">
                                <div class="info-item-desc" @click="$oucy.go('/Address')">
                                    地址管理
                                </div>
                            </el-card>
                        </div>
                    </div>
            </div>

            <div class="el-card-container">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="bottom_left">
                            <span class="news_title">平台公告</span>
                            <el-button type="text" @click="$oucy.go('/AfficheList?type=1')">更多 ></el-button>
                            <div style="margin-top: 20px">
                                <div v-for="(item,index) of noticelist" v-if="index<11" :key="index" class="text item" @click="$oucy.go('/AfficheDetail?type=1&id='+item.id)">
                                    <!-- <span style="color: #E88356;background: #FFEDE5;">最新动态</span> -->
                                    <div class="df-jc-s-b">
                                        
                                    <div>
                                        <i class="el-icon-s-promotion"></i> {{item.noticeTitle}}
                                    </div>
                                        <div class="c-2">{{item.noticeCreatetime}}</div>
                                    </div>
                                </div>
                               <!--  <div class="contribute-container">
                                     <el-button icon="el-icon-edit" size="small" type="primary">投稿</el-button>
                                 </div> -->
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="bottom_left">
                              <div slot="header" class="clearfix">
                                  <span class="news_title">招标公告</span>
                                  <el-button type="text" @click="$oucy.go({ path: '/AfficheList', query: { type:2 }})">更多 ></el-button>
                              </div>
                              <div style="margin-top: 10px">
                                  <div v-if="i<6" @click="$oucy.go('/AfficheDetail?type=2&id='+item.id)" v-for="(item,i) of biddinglist" class="pointer biddinglist " :key="item.id+i">
                                   <div class=" biddinglist_left f14">
                                       <div class="line-1" :title="item.adTitle" style="margin-bottom:10px"><li class="el-icon-tickets"></li> {{item.adTitle}}</div>
                                       <div class="line-1 c-2" :title="item.adUnit">招标单位：{{item.adUnit}}</div>
                                   </div>
                                   <span class="biddinglist_right">{{item.adUpdatetime}}</span>
                                  </div>
                              </div>
                        </div>
                    </el-col>
                </el-row>
            </div>

        </div>

    </div>
</template>

<script>
    import oucy from "../../../util/oucyUtil";
    import {userAuth,bidding,notice,userMaterial } from "@/service/index"
    export default {
        name: "Index",
        data(){
            return{
                userEntity: null,
                i:1,
                isEnterpriseOrBuyAuth:null,
                name:null,
                noticelist:[],//公告列表
                biddinglist:[],//招标公告列表
            }
        },
        mounted() {
            const me = this;
            userMaterial.queryUserMaterial().then(res=>{
                me.userEntity = res;
                console.log(me.userEntity);
            });
            userAuth.isEnterpriseOrBuyAuth().then(res=>{
                this.isEnterpriseOrBuyAuth=res
            })
            this.getBidding()
            this.getANotice()
        },
        methods: {
            //获取平台公告
            getANotice(){
                notice.getAllNotice({}).then(res=>{
                    if(res){

                        this.noticelist = res//公告列表
                    }
                })
            },
            //获取招标公告
            getBidding(){
                bidding.getAllTender({}).then(res=>{
                    if(res){
                        let list=res
                        // let len=res.length
                        // if(8-len>0){
                        //     for (var i = 0; i < 8; i++) {
                        //         if(list.length>=8){
                        //             break
                        //         }
                        //         list.push(...res)
                        //     }
                        // }
                        this.biddinglist = list
                    }
                })


            },
            go: function (path) {
                this.$router.push(path);
            },
            getName(){
                if(this.userEntity.userDcodeUsername){
                    return this.name=this.userEntity.userDcodeUsername
                }else                
                if(this.userEntity && this.userEntity.detailNickname){
                    return this.name=this.userEntity.detailNickname
                }else{
                    return this.name=this.userEntity.userPhoneNumber
                }
            },
            copyName(){
                let dom = document.getElementById("copydom")
                 dom.select();
                 document.execCommand("Copy");
                 this.$message({
                     type: 'success',
                     message: '复制成功!'
                 });
            }
        }
    }
</script>

<style scoped lang="less">
    .index{
        text-align: left;
    }

    .el-card-container{
        margin-bottom: 20px;
    }
    .basicInfo-container{
        background: #FFFFFF;
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding:40px;
    }

    .basicInfo-container-left{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .id-container{
        margin-left: 20px;
        color: #ccc;
        line-height: 40px;
    }

    .image {
        width: 48px;
        height: 48px;
        border-radius: 5px;
        display: block;
        margin: 0 auto;
    }
    .info-item-container{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background: #FFFFFF;
        padding: 20px 60px 40px 60px;
    }
    .info-item-desc{
        margin-top: 6px;
        font-size: 14px;
        color: #555C68;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /*.box-card{*/
    /*    height: 300px;*/
    /*    overflow: scroll;*/
    /*}*/

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }

    .text {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .item {
        margin-bottom: 18px;
        color: #555C68;
    }
    .item:hover{
        cursor: pointer;
        text-decoration: underline;
    }

    .contribute-container{
        position: absolute;
        left: 30px;
        bottom: 30px;
    }

    .my-card-header{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #FFFFFF;
        padding: 40px;
    }

    .my-card-header-money{
        display: flex;
        display: -webkit-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .my-card-header-money span{
        padding:0 5px 0 5px;
    }

    .bottom_left{
        width: auto;
        height: 450px;
        background: #FFFFFF;
        padding:20px;
    }

    .bottom_left .el-button--text{
        float: right;
        padding: 3px 0;
        color: #AAAAAA;
    }
    .middle_button{
        width: 118px;
        height: 117px;
        /*background: #F8F8F8;*/
        border-radius: 10px;
    }
    .middle_button img{
        margin:0 auto;
    }
    .bottom_right{
        width: 425px;
        height: 477px;
        background: #FFFFFF;
        padding:20px;
    }

    .news_title{
        font-size: 16px;
        font-weight: bold;
        color: #555C68;
    }

    .more_news .el-button--text{
        font-size: 14px;
        color: #AAAAAA;
    }

    /deep/ .el-button--text{
        color: #2090FF;
    }

    .warning_auth{
        width: 299px;
        background: #FFF0D8;
        float: right;
        color: #F09953;
        padding:0;
    }
    .biddinglist{
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        .biddinglist_left{
            flex: 1;
            font-size: 12;
            .adTitle{
                margin-bottom: 10px;
            }
        }
        .biddinglist_right{
            text-align: right;
            font-size: 14px;
            width: 100px;
            color: #AAAAAA;
            padding-top: 30px;
        }
    }
    .biddinglist+.biddinglist{
     border-top:1px solid #f1f1f1;
    }
</style>
